
// ============================================
// VENDOR: AOS
// ============================================

@import '../../../node_modules/aos/src/sass/aos.scss';

/* IE11 */
@media all and (-ms-high-contrast:none)
{
*::-ms-backdrop,[data-aos^=fade][data-aos^=fade],[data-aos^=zoom][data-aos^=zoom]{
        opacity: 1;
        transform: none !important;
    }
}
