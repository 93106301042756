form {
    .freeform-form-has-errors {
        li {
            list-style: none;
            color: $cc-red;
        }
    }

    .file-upload {
        input {
            display: none !important;
        }

        .upload-btn {
            cursor: pointer;
            position: relative;
            
            svg {
                position: absolute;
                right: 10px;
                bottom: 10px;
                height: 20px;
            }
        }

        #file-details {
            margin-top: 5px;

            > span {
                margin-right: 10px;
            }

            > button {
                color: var(--purple);
            }
        }
    }

    .input-fields {
        display: flex;
        width: 100%;        

        .Form-controls {
            width: 100%;

            label {
                &:not(.checkbox):not(.upload-btn) {
                    position: absolute;
                    right: 0;
                    font-size: 12px;
                    top: 5px;
                    font-style: italic;
                    opacity: 0;
                    transition: opacity .2s ease;                   
                }
            }  

            &.active {
                label {
                    &:not(.checkbox) {
                        opacity: .1;
                    }
                }
            }

            input, textarea, .upload-btn {
                width: 100%;
                appearance: none;
                font-family: $font-base;
                border: none;
                border-bottom: 1px solid $cc-black;
                padding: 0;
                padding-bottom: 10px;
                font-size: 16px;
                outline: none;
                border-radius: 0;
                display: flex;

                &::placeholder {
                    color: $cc-black;
                }
            }

            input {
                &[type=number] {
                    -moz-appearance: textfield;
                    
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                    }
                }

                &[type=checkbox] {
                    display: none;

                    &:checked {
                        & ~ .checkbox {
                            div {
                                border-color: var(--purple);
                                background-color: var(--purple);
                            }
                        }
                    }
                }
            }

            .checkbox {                
                display: flex;                

                * {
                    cursor: pointer;
                }
                
                div {
                    width: 24px;
                    height: 24px;
                    border: 1px solid $cc-black;
                    transition: border-color .2s ease, background-color .2s ease;
                }

                span {
                    font-size: 12px;
                    color: $cc-black;
                    margin-left: 10px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    line-height: 1;

                    a {
                        &:hover {
                            font-weight: bold;
                        }
                    }
                }

                @include mq($until: $viewport--sm) {
                    align-items: center;
                }
            }

            textarea {
                resize: none;
                max-height: 120px;

                /* width */
                &::-webkit-scrollbar {
                    width: 10px;
                }
                
                /* Track */
                &::-webkit-scrollbar-track {
                    background: #ffffff;
                }
                
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: var(--purple);
                }

                & ~ label {
                    padding-right: 20px;
                }
            }

            .cta {                
                font-size: 16px;
                font-family: $font-base;
            }
            
            &:nth-of-type(2), &:nth-of-type(3) {
                padding-left: 10px;
            }

            &.Form-item--action {
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }
        }

        &.amount {
            &-3 {
                .Form-controls {
                    &:nth-of-type(1) {
                        width: 50%;
                    }
                    
                    &:nth-of-type(2) {
                        width: 25%;
                    }
                    
                    &:nth-of-type(3) {
                        width: 25%;
                    }
                }
            }
        }

        &:last-of-type {
            @include mq($until: $viewport--sm) {
                flex-direction: row !important;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;

                .Form-controls {
                    padding-bottom: 10px;
                    width: auto !important;

                    &.Form-item--action {
                        padding-top: 0 !important;
                    }
                    
                    &:not(.Form-item--action) {                        
                        padding-right: 30px;
                    }
                }
            }
        }
    }

    .input-fields + .input-fields {
        margin-top: 20px;
    }
}