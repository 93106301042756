.form-section {
    padding: 200px 0;
    position: relative;

    .shapes {
        display: flex;
        justify-content: space-between;
        margin-top: $spacing--viewport;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        .left {
            display: grid;
            grid-template-columns: repeat(2, 88px);
            grid-template-rows: repeat(2, 88px);
            grid-template-areas: 'first .'
            '. second';
            grid-row-gap: 4px;

            .first {
                grid-area: first;

                .shape {
                    &.dots {
                        margin-top: 0;
                    }
                }
            }
            
            .second {
                grid-area: second;
            }
        }
    }

    .intro {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 80px;
        max-width: 450px;
        margin-left: auto;
        margin-right: auto;

        h2 {
            margin-bottom: 30px;
        }

        div {
            font-family: $font-secondary;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;                   
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    @include mq($until: 960px) {
        padding-left: 20px;
        padding-right: 20px;
    }
   
    @include mq($until: $viewport--sm) {
        padding: 140px 20px 80px;

        .shapes {
            .left {
                grid-template-columns: repeat(2, 44px);
                grid-template-rows: repeat(2, 44px);

                .shape {
                    &.dots {
                        margin-left: 0;
                        height: 46px;
                    }
                }
            }
        }

        form {
            .input-fields {
                flex-direction: column;
                margin-top: 20px !important;

                .Form-controls {
                    width: 100%;

                    &:nth-of-type(2), &:nth-of-type(3) {
                        padding-left: 0;
                        padding-top: 20px;
                    }
                }
            }
        }
    }
}