// OVERVIEW
.ProductsPagePage {
    .page-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 320px;
        padding: 0 30px;
        max-width: 1100px;
        margin-left: auto;
        margin-right: auto;

        .text {
            margin-top: 40px;
        }

        @include mq($until: $viewport--md) {
            margin-bottom: 220px;
        }
        
        @include mq($until: $viewport--sm) {
            margin-bottom: 160px;

            .text {
                margin-top: 20px;
            }
        }
        
        @include mq($from: 1500px) {
            margin-bottom: 15vw;
        }
    }

    .text {
        max-width: 760px;
        margin: 0 auto;
        text-align: center;
        
        &-container {
            background-color: $cc-lightGrey;
            padding: 120px 40px;
        }
    }

    .products {
        margin-bottom: -10vw;
    }
}

// DETAIL
.ProductsPage {
    .page-header {
        &.fullscreen {
            margin-top: -160px;
            position: relative;
            margin-bottom: 240px;

            @include mq($until: 1500px) {
                margin-top: -160px;
            }
            
            @include mq($until: 1150px) {
                margin-top: -60px;
            }
            
            @include mq($until: $viewport--sm) {
                margin-top: 0px;
            }
        }

        .text {
            margin-top: 20px;
            
            &-container {                
                max-width: inherit;

                .cta {
                    margin-top: 40px;
                }

                &--container {                    
                    max-width: 440px;
                    margin-bottom: 60px; 
                    margin-left: 10vw;
                    position: absolute;
                    margin-bottom: 0;
                    bottom: -86px;
                    margin-top: -325px;   
                    z-index: 1;

                    @include mq($from: 1500px) {
                        margin-left: 20vw;
                    }                                    
                    
                    .shape {
                        position: absolute;
                        right: -76px;
                        bottom: -76px;
                        z-index: -1;
                    }

                    @include mq($until: $viewport--md) {
                        position: relative;
                        margin-top: -320px;
                        max-width: unset;
                        margin-left: 30px;
                        margin-right: 30px;

                        .shape {
                            display: none;
                        }
                    }
                    
                    @include mq($until: 400px) {
                        margin-left: 10px;
                        margin-right: 10px;
                    }
                }
                
                @include mq($until: 400px) {
                    padding: 40px 10px !important;
                }

                &.background {
                    &-blue, &-green, &-orange, &-yellow, &-purple, &-pink {
                        padding: 70px 40px;

                        * {
                            color: white;
                        }
                    }
        
                    &-blue {
                        background-color: var(--blue);
                    }
        
                    &-green {
                        background-color: var(--green);
                    }
        
                    &-orange {
                        background-color: var(--orange);
                    }           
                    
                    &-yellow {
                        background-color: var(--yellow);
                    }
        
                    &-purple {
                        background-color: var(--purple);
                    }
        
                    &-pink {
                        background-color: var(--pink);
                    }           
                }

            }
        }

        .image {
            height: 100%;
        }

        @include mq($until: 1000px) {
            padding-left: 30px;
            padding-right: 30px;

            .image-container {
                &.pr-2 {
                    padding-right: 0 !important;
                }
            }
        }        
        
        @include mq($until: $viewport--sm) {            
            flex-direction: column;

            .text-container {
                margin-right: 0;
                margin-bottom: 30px;
            }
        }       
    }

    .intro {
        &-block {
            grid-template-columns: 520px auto 1fr;
            grid-template-areas:
            'text . image1';
            align-items: center;

            .figure {
                &-1 {
                    .image {
                        max-width: 520px;
                    }
                }

                .shape {
                    &.lines {
                        right: unset;
                        left: 0;
                        bottom: -94px;
                        top: unset;
                    }
                }
            }

            .text {
                margin-top: 6px;
                margin-left: unset;
            }

            &--container {

            }

            @include mq($until: 1060px) {
                grid-template-columns: 430px auto;   
                padding: 0 30px;             
            }
            
            @include mq($until: 850px) {
                display: flex;
                flex-direction: column;
                align-items: center;

                .text {
                    margin-left: auto;
                    margin-right: auto;
                }

                .figure {
                    &-1, &-2 {
                        .image {
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                }
            }

            @include mq($until: $viewport--md) {
                .text {
                    font-size: 18px;
                    line-height: 26px;
                }
            }
            
            @include mq($until: $viewport--sm) {
                .text {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }

    .Main {
        .image {
            &.fullscreen {
                margin-left: $spacing--viewport;
                margin-right: $spacing--viewport;
            }
        }
    }

    .textblock-container {
        margin-top: 160px;
        margin-bottom: 240px;
        
        .textblock {
            flex-direction: row-reverse;

            .shape {
                &:nth-of-type(1) {
                    right: unset;
                    left: -94px;
                }
               
                &:nth-of-type(2) {
                    width: 88px;
                    height: 88px;
                    right: -94px;
                    bottom: -94px;
                }
               
                &:nth-of-type(3) {
                    height: 88px;
                    bottom: -188px;
                    right: 0;
                }
            }
        }

        @include mq($until: $viewport--md) {
            .textblock {
                flex-direction: column-reverse;            
                align-items: center;
    
                .info {
                    margin-top: 240px;
                }
            }
        }
        
        @include mq($until: $viewport--sm) { 
            margin-bottom: 100px;

            .textblock {
                .info {
                    margin-top: 140px;
                    max-width: 380px;
                }

                .shape {
                    &:nth-of-type(1) {
                        top: -50px;
                        left: -50px;
                    }
                    
                    &:nth-of-type(2) {
                        left: unset !important;
                        width: 44px;
                        height: 44px;
                        right: -50px;
                        bottom: -50px;
                    }
                    
                    &:nth-of-type(3) {
                        height: 44px;
                        bottom: -100px;
                    }
                }
            }
        }
    }

    .tussentext {
        &-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 200px;
            margin-bottom: 200px;
            padding-left: 20px;
            padding-right: 20px;

            .big-text, .small-text {
                text-align: center;
            }

            .big-text {
                max-width: 560px;
                font-size: 30px;
                line-height: 1;
                max-width: 720px;
            }

            .small-text {
                font-family: $font-secondary;
                font-size: 16px;
                margin-top: 20px;
            }

            .cta {
                margin-top: 30px;
            }

            @include mq($until: $viewport--md) {
                margin-top: 140px;
                margin-bottom: 200px;

                .big-text {
                    font-size: 26px;
                }
            }
            
            @include mq($until: $viewport--sm) {
                margin-top: 80px;
                margin-bottom: 140px;

                .big-text {
                    font-size: 22px;
                }
            }
        }
    }

    .slider {
        margin-bottom: 200px;

        @include mq($until: $viewport--sm) {
            margin-bottom: 120px;
        }
    }
}