.BrandsPage {
    .page-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 320px;
        padding: 0 30px;
        max-width: 1100px;
        margin-left: auto;
        margin-right: auto;

        .text {
            margin-top: 40px;
        }

        @include mq($until: $viewport--md) {
            margin-bottom: 220px;
        }
        
        @include mq($until: $viewport--sm) {
            margin-bottom: 80px;

            .text {
                margin-top: 20px;
            }
        }
    }
    
    .brands {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        .brand {
            &:nth-of-type(odd) {
                .textblock {
                    flex-direction: row-reverse;

                    .info {
                        margin-right: 0;
                        margin-left: 30px;
                    }
                }
            }

            .figure {
                position: relative;
                z-index: -1;
            }

            .textblock {                
                .info {
                    max-width: 440px;
                    margin-right: 40px;
                }
            }
            
            @include mq($from: $viewport--sm) {                
                &.odd {
                    .shape {
                        &:nth-of-type(1) {
                            width: 88px;
                            height: 88px;
                            top: unset;
                            bottom: -94px;
                            right: 0;
                        }

                        &:nth-of-type(2) {                            
                            width: 176px;
                            height: 88px;
                            top: unset;
                            bottom: -188px;
                            right: 92px;
                        }                        
                    }
                }
            }

            @include mq($until: $viewport--sm) {                
                .textblock {
                    flex-direction: column-reverse !important;

                    .info {
                        margin-left: 0 !important;
                        margin-right: 0 !important;
                        margin-top: 100px;
                    }

                    .info, .figure {
                        max-width: 320px;
                    }
                }

                &:nth-of-type(odd) {
                    .info {
                        margin-top: 120px;
                    }
                }

                &.odd {
                    .shape {
                        &:nth-of-type(1) {
                            right: -50px;
                            top: -50px;
                            left: unset;
                        }
                        
                        &:nth-of-type(2) {
                            left: -94px !important;
                            right: unset;
                            bottom: -94px;
                        }
                    }
                }
            }
        }
    }
}
