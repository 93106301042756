.ContactPage {
    .page {
        &-header {
            margin: 0 auto;
            display: flex;
            justify-content: flex-start;  
            
            h1 {
                margin-right: 0;
                min-width: 400px;
            }
    
            .branches {
                margin-top: 10px;
                margin-left: 100px;
            }

            @include mq($until: 1060px) {
                flex-direction: column;
                max-width: 740px;
                margin-left: auto;
                margin-right: auto;

                .branches {
                    display: grid;
                    grid-template-columns: repeat(2, auto);
                    margin-left: 20px;
                    max-width: 560px;
                    margin-top: 40px;
                }
            }

            @include mq($until: $viewport--md) {
                flex-direction: column;                      
    
                h1 {
                    margin-right: 0;
                    margin-bottom: 30px;
                }

                .branches {
                    margin-left: 20px;
                }
            }
            
            @include mq($until: $viewport--sm) {
                .branches {                    
                    grid-template-columns: auto;
                    margin-top: 0px;
                }
            }
        }

        &-content {
            margin-left: $spacing--viewport;
            margin-right: $spacing--viewport;

            .images-container {
                display: grid;
                grid-template-columns: 380px 1fr;
                max-width: 1060px;
                width: 100%;
                grid-column-gap: 100px;
                margin-left: auto;
                margin-right: auto;
                padding-left: 20px;
                margin-bottom: 188px;

                .image {
                    width: 100%;
                    height: 100%;

                    &-container {
                        position: relative;

                        &:first-of-type {
                            margin-top: -160px;
                        }

                        .shape {
                            &:nth-of-type(1), &:nth-of-type(2) {
                                position: absolute;
                            }

                            &:nth-of-type(1) {
                                right: 0;
                                bottom: -94px;
                            }
                            
                            &:nth-of-type(2) {
                                width: 176px;
                                right: 94px;
                                bottom: -188px;
                            }

                            @include mq($until: $viewport--sm) {
                                &:nth-of-type(1) {
                                    bottom: -50px;
                                }                                
                                
                                &:nth-of-type(2) {
                                    width: 94px;
                                    right: 50px;
                                    bottom: -98px;
                                }                                
                            }
                        }
                    }
                }

                @include mq($until: 1200px) {
                    .image {
                        &-container {
                            &:first-of-type {
                                margin-top: -400px;
                            }
                            
                            &:nth-of-type(2) {
                                width: 100% !important;                                
                            }
                        }
                    }
                }
                
                @include mq($until: 1060px) {
                    display: flex;
                    justify-content: center;
                    padding-left: 0;
                    
                    .image {
                        &-container {
                            &:first-of-type {
                                display: none;                                
                            }

                            &:nth-of-type(2) {
                                max-width: 700px;
                            }
                        }
                    }
                }

                @include mq($until: $viewport--sm) {
                    margin-bottom: 98px;
                }
            }
        }
    }
}