.Footer {    
    margin-top: $spacing--viewport;

    .cta {
        &:before, &:after {
            top: 0px;
        }
    }

    .socials {
        a {
            width: 13px;
            height: 13px;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    .cta-section {
        > .image-container {
            margin-left: 0 !important;
            margin-right: 0 !important;
            
            * {
                height: 100%;
            }
        }   

        .background {
            &-blue, &-green, &-orange, &-yellow, &-purple, &-pink {
                * {
                    color: white;
                }
            }

            &-blue {
                background-color: var(--blue);
            }

            &-green {
                background-color: var(--green);
            }

            &-orange {
                background-color: var(--orange);
            }           
            
            &-yellow {
                background-color: var(--yellow);

                * {
                    color: black;
                }

                .cta {
                    &:after {
                        content: url("data:image/svg+xml;charset=utf8,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.3058 15.6286C10.1308 15.6285 9.96298 15.5589 9.83933 15.435C9.71568 15.3112 9.64628 15.1433 9.64641 14.9683C9.64653 14.7933 9.71616 14.6256 9.83999 14.5019C9.96382 14.3783 10.1317 14.3089 10.3067 14.309H17.9466L15.3651 11.7275C15.2414 11.6039 15.172 11.4361 15.172 11.2612C15.172 11.0863 15.2414 10.9186 15.3651 10.7949C15.4888 10.6713 15.6565 10.6018 15.8314 10.6018C16.0063 10.6018 16.1741 10.6713 16.2977 10.7949L20.0048 14.502C20.1285 14.6257 20.198 14.7934 20.198 14.9683C20.198 15.1432 20.1285 15.311 20.0048 15.4346L16.2977 19.1417C16.1741 19.2654 16.0063 19.3349 15.8314 19.3349C15.6565 19.3349 15.4888 19.2654 15.3651 19.1417C15.2414 19.0181 15.172 18.8503 15.172 18.6754C15.172 18.5005 15.2414 18.3328 15.3651 18.2091L17.9466 15.6277L10.3058 15.6286Z' fill='black'/%3E%3Crect x='3.75' y='3.51465' width='22.5' height='22.5' stroke='black' stroke-width='1.5'/%3E%3C/svg%3E");
                    }
                }
            }

            &-purple {
                background-color: var(--purple);
            }

            &-pink {
                background-color: var(--pink);
            }           
        }
    }

    .cta-banner {
        &--container {        
            margin-bottom: $spacing--viewport;
        }
    }
    
    .image {
        margin-bottom: $spacing--viewport;
        max-height: 640px;

        img {
            max-height: inherit;
        }
    }

    .bottom {
        display: grid;
        grid-template-columns: 90% 10%;
        grid-template-areas: 'info shape';
        margin-bottom: $spacing--viewport;

        .info {            
            display: flex;
            flex-direction: column;
            max-width: 1060px;
            width: 100%;  

            .contact, .branches {
                > * {
                    display: flex;
                    flex-direction: column;
                }
            }

            .contact {
                .logo {
                    width: 225px;
                    height: 35px;
    
                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }

                &-info {
                    margin-top: 20px;
                }
            }

            p, a {
                line-height: 30px;
            }

            &-container {
                grid-area: info;
                background-color: $cc-lightGrey;            
                padding: 120px $spacing--normal;
                margin-right: $spacing--viewport;
                display: grid;
                grid-template-columns: auto auto 1fr;
                grid-column-gap: 10vw;
            }
        }
     
        .shape {
            grid-area: shape;
            width: 100%;
            height: 100%;
        }
    }    

    .privacy {
        padding-right: 100px;
        
        > a {
            margin-bottom: 5px;
            margin-right: 20px;
        }

        &-container {
            margin-bottom: $spacing--viewport;
            background-color: $cc-white;
            padding: 40px 180px;
            width: 100%;
        }
        
        &-content {
            display: flex;
            justify-content: space-between;
           
            max-width: 1060px;
            width: 100%;
            margin-right: 100px;

            .site-by {
                span {
                    transition: color .2s ease;
                }

                &:hover {
                    span {
                        color: var(--purple);
                    }
                }
            }
        }
    }

    @include mq($until: 1100px) {
        .bottom {
            .info {
                &-container {
                    padding: 120px 80px;
                }
            }
        }

        .privacy {
            &-container {
                padding: 40px 80px;
            }
        }
    }

    @include mq($until: 960px) {
        .bottom {
            grid-template-columns: auto;
            grid-template-rows: auto 220px;
            grid-template-areas:
                'info'
                'shape';
            
            .info {
                &-container {
                    padding: 120px 0 120px 100px;
                    margin-bottom: $spacing--viewport;
                    margin-right: 0;
                }
            }
        }

        .privacy {
            &-container {
                padding: 40px 100px;
            }
        }
    }

    @include mq($until: $viewport--md) {
        .bottom {
            .info {
                &-container {
                    padding: 80px 40px;
                    grid-template-columns: 1fr;
                    grid-row-gap: 50px;
                }
            }
        }

        .privacy {
            &-container {
                padding: 40px 40px;
            }
        }
    }

    @include mq($until: $viewport--sm) {
        .bottom {
            .info {
                grid-template-columns: auto;
                margin-right: 0;

                &-container {
                    padding: 50px 40px;
                }

                .contact {
                    .logo {
                        width: 155px;
                        height: 25px;
                        margin-bottom: 40px;
                    }

                    * {
                        min-height: unset !important;
                    }

                    .socials {
                        margin-top: 10px;
                    }
                }
                
                .branches {
                    margin-top: 40px;

                    .branch {
                        min-height: unset !important;
                    }

                    .branch + .branch {
                        margin-top: 30px;
                    }
                }
            }
        }

        .privacy {
            display: flex;
            flex-direction: column;
            padding-right: 0;

            &-container {
                width: 100%;
                padding: 10px 20px;
            }

            &-content {
                grid-template-columns: auto auto;
                align-items: flex-start;    
                justify-content: space-between;           
            }
        }
    }
}