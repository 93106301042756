html, body {
    font-family: $font-base;
    overflow-x: hidden;
}

a {
    color: $cc-black;
    text-decoration: none;

    &:hover, &:active, &:focus {
        color: $cc-black;
    }
}

p {
    margin-bottom: 0;
}

h1 {
    font-size: 50px;
    font-weight: 400;
    line-height: 1;

    @include mq($from: $viewport--xxl) {
        font-size: 55px;
    }

    @include mq($until: $viewport--md) {
        font-size: 40px;
    }
    
    @include mq($until: $viewport--sm) {
        font-size: 28px;
    }
}

h2 {
    font-size: 30px;
    font-weight: 400;
    line-height: 38px;
    
    @include mq($from: $viewport--xxl) {
        font-size: 35px;
        line-height: 42px;
    }

    @include mq($until: $viewport--sm) {
        font-size: 20px;
        line-height: 28px;
    }
}

h3 {
    font-size: 20px;
    font-weight: 400;
    line-height: 1;
}

.text {
    font-family: $font-secondary;
}

.page-header {
    h1, .text {
        padding-left: 20px;
        padding-right: 20px;
    }

    .cta {
        margin-left: 20px;
    }
}

.page-header__flex {
    @include mq($from: 1280px) {
        padding-left: 0 !important;
        display: flex;
        justify-content: space-between;

        h1 {
            min-width: 480px;
            padding-left: 0 !important;
            padding-right: 22px !important;
        }
        
        .text {
            padding: 0 !important;
            margin-top: 0 !important;
            max-width: 370px !important;
        }
    }
}

.grecaptcha-badge {
    z-index: 2;
}

.animateinwidth, .animatein {
    &.background-image, &.shape {
        transition: width .4s ease;
        transition-delay: 0s;
    }

    * {
        transition: width .4s ease;
        transition-delay: 0s;
    }

    @include mq($from: $viewport--sm) {
        &.not-fired {
            &.background-image, &.shape {
                width: 0px !important;    
            }
            
            * {
                width: 0px !important;
            }
        }
    }
}

.fadein {
    transition: opacity .4s ease, transform .4s ease;

    &.not-visible {
        opacity: 0;
        transform: translateY(50px);
    }
}