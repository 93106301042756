.pagination {
    padding-top: 200px;
    display: flex;
    justify-content: center;

    .pages {
        display: flex;
        justify-content: center;

        &.reversed {
            flex-direction: row-reverse;
        }

        .page {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 2px;
            background-color: $cc-white;
            font-size: 20px;

            &.active {
                background-color: var(--purple);
                color: $cc-white;
            }
        }
    }

    @include mq($until: $viewport--md) {
        padding-top: 100px;
    }

    @include mq($until: $viewport--sm) {
        padding-top: 0;
        padding-bottom: 80px;
    }
}