.video-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;                     

    img, video {
        width: 100%;
        height: 100%;
        max-height: inherit;
        object-fit: cover;
    }                            

    video {
        width: 100%;
        height: 820px;

        &:hover {
            & ~ .play-button-wrapper {
                .circle-play-b {
                    opacity: 1 !important;
                }
            }
        }

        @include mq($until: 1400px) {
            height: 500px;
        }
       
        @include mq($until: 1100px) {
            height: 400px;
        }
       
        @include mq($until: $viewport--sm) {
            height: 250px;
        }
    }

    .play-button-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        pointer-events: none;
        z-index: 1;        

        .circle-play-b {
            cursor: pointer;
            pointer-events: all;
            transition: opacity .2s ease;

            &:hover {
                // opacity: 1 !important;
            }

            &:before {
                content: '';
                background-color: rgba($cc-black, .4);
                transition: background-color .2s ease;
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: -1;
                left: 0;
                top: 0;
            }
        }
    }
    
    @include mq($until: $viewport--sm) {                                        
        .play-button-wrapper {
            svg {
                width: 50px;
            }
        }
    }
}