.JobsOverviewPage, .JobsPage {
    .Header {
        margin-bottom: 100px;

        @include mq($until: $viewport--md) {
            margin-bottom: 40px;
        }
    }

    .page-header {
        display: flex;
        align-items: center;
        padding-left: 10vw;
        padding-right: 8px;

        .text-container {
            width: 40vw;
            margin-right: 100px;

            .cta {
                margin-top: 30px;
                white-space: nowrap;
            }

            .location-container {
                padding-left: 20px;
                padding-right: 20px;

                span {
                    opacity: .45;
                }
            }
        }

        .jobs-image {
            width: 60vw;
            height: 100%;
            position: relative;
            min-height: 450px;
            height: 30vw;   
            
            .splide {
                min-height: inherit;
                height: inherit;
                
                &__track {
                    min-height: inherit;
                    height: inherit;

                    * {
                        min-height: inherit;
                        height: inherit;
                    }
                }    
                
                &__arrows {
                    position: absolute;
                    bottom: 20px;
                    right: 20px;
                    display: flex;

                    button {
                        &.next {
                            margin-left: 10px;
                        }

                        svg {
                            path {
                                fill: white;
                            }
        
                            rect {
                                fill: transparent;
                                stroke: white;
                            }
                        }
                    }
                }
            }
    
            .shape-container {
                position: absolute;
                top: 0;
                left: -94px;
                
                .shape {                
                    width: 88px;

                    &.dots {
                        margin-top: 0;
                        margin-left: 0;
                    }
                }
            }
            
            &--splide {
                width: 100%;
                height: 100%;
            }

            .image {
                height: 100%;
            }
        }

        @include mq($until: 1100px) {
            padding-left: 20px;

            .text-container {
                margin-right: 30px;
            }
        }
        
        @include mq($until: $viewport--md) {
            flex-direction: column;
            align-items: flex-start;
            padding: 0 40px;

            .text-container {
                width: 100%;
                margin-right: 0;

                h1 {
                    padding-left: 0;            
                }

                .cta {
                    margin-left: 0;
                }

                .location-container {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            .jobs-image {
                margin-top: 40px;
                width: 100%;
                min-height: unset;
                height: 350px;
            }
        }
    }
}

.JobsOverviewPage {
    .intro-section {
        @include mq($until: $viewport--md) {
            margin-bottom: 50px;
            margin-top: 100px !important;  
        }

        @include mq($until: $viewport--sm) {
            margin-bottom: 50px;      
        }
    }

    .jobs {
        padding-top: 80px;

        .pagination {
            padding-top: 100px;
            margin-bottom: 100px;
            
            @include mq($until: $viewport--sm) {
                padding-top: 60px;
                margin-bottom: 60px;           
            }
        }        

        &-item {
            position: relative;
            
            .shape {
                position: absolute;
                top: -94px;
                left: -94px;
            }

            .left {
                background-color: rgba(0,0,0,0.03);
                padding: 50px 40px;

                .location {
                    display: flex;
                    align-items: center;

                    span {
                        color: rgba(0,0,0,0.45);
                    }
                }

                .text {
                    max-width: 520px;
                    width: 100%;
                    margin-right: 40px;
                }

                .arrow {
                    min-width: 50px;
                    max-width: 50px;
                    min-height: 50px;
                    max-height: 50px;

                    svg {
                        width: 100%;
                        height: 100%;

                        rect {
                            stroke-width: 1;
                        }
                    }
                }
            }

            .right {

            }

            &--image {
                width: 100%;
                height: 100%;

                .image {
                    height: 100%;
                }
            }

            &.spontaan {                
                .left {
                    &.background {
                        &-blue {
                            background-color: var(--blue);
                        }
            
                        &-green {
                            background-color: var(--green);
                        }
            
                        &-orange {
                            background-color: var(--orange);
                        }           
                        
                        &-yellow {
                            background-color: var(--yellow);
                        }
            
                        &-purple {
                            background-color: var(--purple);
                        }
            
                        &-pink {
                            background-color: var(--pink);
                        } 
                    }

                    * {
                        color: white;
                    }

                    .arrow {
                        svg {
                            rect {
                                stroke: white;                                
                            }

                            path {
                                fill: white;                                
                            }
                        }
                    }
                }
            }

            .arrow {
                svg {
                    rect {
                        fill: rgba(255, 255, 255, 0);            
                        transition: stroke .4s ease, fill .4s ease;       
                    }

                    path {
                        transition: fill .4s ease;                         
                    }
                }
            }

            &:hover {
                .arrow {
                    svg {
                        rect {
                            stroke: white;
                            fill: var(--purple);                        
                        }

                        path {
                            fill: white;                            
                        }
                    }
                }
            }
        }

        @include mq($until: 1140px) {
            .jobs-item {
                grid-template-columns: 70% 30% !important;
                max-width: 1100px;
                width: 100%;
                padding: 0 20px;
            }
        }
        
        @include mq($until: $viewport--md) {
            .jobs-item {
                grid-template-columns: 1fr !important;

                .right {
                    display: none;
                }
            }
        }
        
        @include mq($until: $viewport--sm) {
            .jobs-item {
                .left {
                    padding: 30px 20px;
                    
                    .text {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .slider-container {
        margin-top: 200px;
        margin-bottom: 200px;

        @include mq($until: $viewport--md) {
            margin-top: 100px;
            margin-bottom: 80px;
        }
        
        @include mq($until: $viewport--sm) {
            margin-top: 50px;            
        }
    }

    .spacing {
        margin-bottom: 160px;

        @include mq($until: $viewport--md) {
            margin-bottom: 80px;
        }
        
        @include mq($until: $viewport--sm) {
            margin-bottom: 50px;            
        }
    }
}

.JobsPage {
    .page-content--container {
        margin-top: 100px;
        margin-bottom: 100px;

        .page-content--text {
            h3 {
                font-weight: bold;
            }

            > div {
                font-family: $font-secondary;

                * {
                    font-family: inherit;
                }
            }

            ul {
                padding-left: 18px;
                
                li {
                    list-style: disc;
                }
            }
        }

        .page-content--text + .page-content--text {
            margin-top: 80px;
        }
    }
}