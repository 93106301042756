.social {
    &-icon {
        width: 20px;
        height: 20px;
        border: 1px solid white;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            display: flex;
            align-items: center;
            
            svg {
                height: 8px;
            }
        }

        &.dark {
            border-color: black;

            svg {
                path {
                    fill: black;
                }
            }
        }

        &.bigger {
            width: 25px;
            height: 25px;

            a {
                svg {
                    height: 12px;
                }
            }
        }
    }
}