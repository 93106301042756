.products {
    display: grid;
    grid-template-columns: repeat(3, 20vw);
    grid-gap: 80px;
    justify-content: center;
    transform: translateY(-10vw);
    position: relative;

    &-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        &--intro {
            max-width: 640px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 80px;
            
            h2 {
                text-align: center;
                max-width: 500px;
            }

            .text {
                max-width: 640px;
                text-align: center;
                margin-top: 20px;
                margin-bottom: 10px;
                padding: 0 20px;
            }

            .cta {
                margin-top: 30px;
            }
        }
    }    

    &-container {
        background-color: $cc-lightGrey;
        padding-bottom: 50px;
        margin-top: calc(10vw + 25px);
        width: 100%;
    }

    .shape {
        position: absolute;
        top: -92px;
        right: -88px;
        margin-left: 0;
        margin-top: 0px;
        background-color: unset !important;
    }

    .product {
        min-width: 300px;
        min-height: 300px;
        width: 20vw;
        height: 20vw;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 0;

        &:nth-child(even) {
            .background {
                opacity: 1;
            }

            &:hover {
                .background {
                    opacity: 0;
                }
            }
        }
         
        &:nth-child(odd) {
            &:hover {
                .background {
                    opacity: 1;
                }
            }
        } 
        
        &:nth-child(even), &:nth-child(odd) {
            &.single {
                &:not(.has-image) {
                    .background {
                        opacity: 1;
                    }
                }
            }
        }
       
        &.single {
            pointer-events: none;

            .content {
                h2 {
                    max-width: 180px;
                }
            }
        }

        .background {
            opacity: 0;
            position: absolute;
            z-index: 0;
            transition: opacity .2s ease;
            width: 100%;
            height: 100%;                    
        }

        .content {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;

            h2 {
                color: $cc-white;
                text-align: center;
                font-size: 25px;
                line-height: 30px;
            }

            .arrow {
                position: absolute;
                bottom: 30px;
                min-width: 30px;
                min-height: 30px;
                width: 1.5vw;
                height: 1.5vw;
                
                svg {
                    min-width: inherit;
                    min-height: inherit;
                    width: inherit;
                    height: inherit;

                    path {
                        fill: $cc-white;
                    }

                    rect {
                        stroke: $cc-white;
                    }
                }
            }
        }

        .image {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -2;

            &:before {
                content: '';
                background-color: #000000;
                opacity: .12;
                z-index: 1;
                position: absolute;
                width: 100%;
                height: 100%;
            }
        }
    }


    @include mq($until: 1280px) {
        grid-template-columns: repeat(3, 28vw);
        grid-gap: 40px;
        transform: translateY(-132px);

        &.container {
            max-width: 880px;
        }

        &-container {
            margin-top: 132px;
        }

        .product {
            min-width: 28vw;
            min-height: 28vw;
        }
    }
    
    @include mq($until: 1100px) {
        grid-gap: 20px;
        transform: translateY(-110px);       

        &-container {
            margin-top: 110px;
            padding-bottom: 0;
        }

        .product {
            .content {
                h2 {
                    font-size: 20px;
                    line-height: 26px;
                }
            }
        }
    }

    @include mq($until: 920px) {
        grid-template-columns: repeat(2, 250px);
        grid-gap: 40px;
        transform: translateY(-125px);
        
        &.container {
            max-width: 540px;
        }

        &-container {
            margin-top: 125px;
        }

        .product {
            width: 250px;
            height: 250px;
        }
    }
    
    @include mq($until: $viewport--md) {
        grid-template-columns: repeat(2, 210px);
        grid-gap: 40px;
        transform: translateY(-105px);
        
        &.container {
            max-width: 460px;
        }

        &-container {
            margin-top: 105px;
        }

        .product {
            width: 210px;
            height: 210px;
        }
    }
   
    @include mq($until: $viewport--sm) {
        grid-template-columns: 240px;
        grid-gap: 40px;
        transform: translateY(-120px);

        .shape {
            right: unset;
            left: -45px !important;
            top: -50px;
        }
        
        &.container {
            max-width: 240px;
        }

        &-container {
            margin-top: 120px;
        }

        .product {
            width: 240px;
            height: 240px;
        }
    }
}