.transition-opacity {
    transition: opacity .5s ease;
}

.load-content {
    transition: opacity 1.5s ease;

    &.is-loading {
        opacity: 0;
    }
}

html {    
    &.loadingscreen-active {
        overflow: hidden;      
    }

    &.is-loaded {
        padding-top: 0;
    }
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 100000;

    img {
        max-width: 200px;
        max-height: 350px;

        @include mq($until: $viewport--sm) {
            max-width: 140px;
            max-height: 245px;
        }
    }

    &.finished {
        opacity: 0;
        pointer-events: none;
    }

    &.removed {
        position: absolute;
        visibility: hidden;
        display: none;
    }
}