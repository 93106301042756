/* Settings: Fonts */

// Default
$font-serif:                            Georgia, "Times New Roman", Times, serif !default;
$font-code:                             Menlo, Monaco, Consolas, monospace, serif !default;

// Custom
$font-base:                      "Circular", sans-serif !default; //200, 300, 400, 500, 600, 700, 800, 900
$font-secondary:                      "Karla", sans-serif !default; //200, 300, 400, 500, 600, 700, 800, 900

@font-face {
    font-family: 'Circular';
    src: url('../fonts/circular/CircularStd-Black.woff2') format('woff2'),
        url('../fonts/circular/CircularStd-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular';
    src: url('../fonts/circular/CircularStd-BookItalic.woff2') format('woff2'),
        url('../fonts/circular/CircularStd-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Circular';
    src: url('../fonts/circular/CircularStd-Bold.woff2') format('woff2'),
        url('../fonts/circular/CircularStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular';
    src: url('../fonts/circular/CircularStd-BlackItalic.woff2') format('woff2'),
        url('../fonts/circular/CircularStd-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Circular';
    src: url('../fonts/circular/CircularStd-Book.woff2') format('woff2'),
        url('../fonts/circular/CircularStd-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular';
    src: url('../fonts/circular/CircularStd-MediumItalic.woff2') format('woff2'),
        url('../fonts/circular/CircularStd-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Circular';
    src: url('../fonts/circular/CircularStd-BoldItalic.woff2') format('woff2'),
        url('../fonts/circular/CircularStd-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Circular';
    src: url('../fonts/circular/CircularStd-Medium.woff2') format('woff2'),
        url('../fonts/circular/CircularStd-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url('../fonts/karla/Karla-ExtraBold.woff2') format('woff2'),
        url('../fonts/karla/Karla-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url('../fonts/karla/Karla-SemiBold.woff2') format('woff2'),
        url('../fonts/karla/Karla-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url('../fonts/karla/Karla-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/karla/Karla-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url('../fonts/karla/Karla-Medium.woff2') format('woff2'),
        url('../fonts/karla/Karla-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url('../fonts/karla/Karla-BoldItalic.woff2') format('woff2'),
        url('../fonts/karla/Karla-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url('../fonts/karla/Karla-ExtraLight.woff2') format('woff2'),
        url('../fonts/karla/Karla-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url('../fonts/karla/Karla-ExtraLightItalic.woff2') format('woff2'),
        url('../fonts/karla/Karla-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url('../fonts/karla/Karla-Italic.woff2') format('woff2'),
        url('../fonts/karla/Karla-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url('../fonts/karla/Karla-MediumItalic.woff2') format('woff2'),
        url('../fonts/karla/Karla-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url('../fonts/karla/Karla-Light.woff2') format('woff2'),
        url('../fonts/karla/Karla-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url('../fonts/karla/Karla-Bold.woff2') format('woff2'),
        url('../fonts/karla/Karla-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url('../fonts/karla/Karla-Regular.woff2') format('woff2'),
        url('../fonts/karla/Karla-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url('../fonts/karla/Karla-ExtraBoldItalic.woff2') format('woff2'),
        url('../fonts/karla/Karla-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url('../fonts/karla/Karla-LightItalic.woff2') format('woff2'),
        url('../fonts/karla/Karla-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}





