.branches {
    &.expanded {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 100px;

        .branch {
            margin-bottom: 100px;
            display: flex;
            flex-direction: column;

            * {
                line-height: 30px;
                
                font-family: $font-secondary;
            }

            .name {
                margin-bottom: 10px;
            }

            a {
                &:hover {
                    font-weight: bold;
                }
            }
        }

        @include mq($until: 1200px) {
            .branch {
                margin-bottom: 50px;

                &:nth-of-type(odd) {
                    margin-right: 60px;
                }
            }
        }

        @include mq($until: 1000px) {
            grid-template-columns: auto;

            .branch {
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    .branch {
        .name {
            font-weight: bold;
            font-family: $font-base;
        }
    }
}