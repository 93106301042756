/* Colors: Custom */
//Example: $cc-[NAME]: #......

// [PROJECTNAME] colors
$cc-white: white;
$cc-lightestGrey: #f0f0f0 !default;
$cc-lightGrey: #F7F7F7 !default;
$cc-grey: #B9B9B9 !default;
$cc-darkGrey: #888888 !default;
$cc-darkerGrey: #8B8B8B !default;
$cc-darkestGrey: #3B3B3B !default;
$cc-black:#000000 !default;
$cc-blackShade: #435572 !default;
$cc-red: #db4444 !default;

:root {
    --yellow: #FFD98C;
    --green: #ABCF9E;
    --blue: #B5DEF2;
    --purple: #D6BDE8;
    --pink: #EBBDC7;
    --orange: #F2C29E;    
}
