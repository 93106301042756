// Enkel voor een basis intro die overal herbruikbaar kan zijn als het nodig is
.intro-section {
    text-align: center;
    max-width: 880px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 120px;
    padding-left: 20px;
    padding-right: 20px;
}

.intro-block {
    display: grid;
    grid-template-columns: 370px auto 520px;
    grid-template-rows: repeat(5, auto);
    grid-template-areas: '. . image2'
    'image1 . image2'
    'image1 . .'
    'image1 . text'
    '. . text';

    .intro--splide {
        grid-area: image1;
        width: 100%;
        max-width: 560px;
        
        .image {
            width: 100%;
            height: 400px;
            border: 8px solid white;
            background-color: white;

            .parallax-content {
                height: 120%;
                transition: width .4s ease, transform .4s ease;
            }

            @include mq($until: $viewport--sm) {
                height: 300px;
            }
        }

        .splide-nav {
            position: absolute;
            right: 0;
            bottom: -80px;
            display: flex;
            justify-content: center;
            padding: 20px;
            margin-right: .5rem;

            &.background {
                &-blue {
                    background-color: var(--blue);
                }
    
                &-green {
                    background-color: var(--green);
                }
    
                &-orange {
                    background-color: var(--orange);
                }           
                
                &-yellow {
                    background-color: var(--yellow);
                }
    
                &-purple {
                    background-color: var(--purple);
                }
    
                &-pink {
                    background-color: var(--pink);
                }  
            }

            button {
                cursor: pointer;
                outline: none;
                width: 40px;
                height: 40px;

                &.next {
                    margin-left: 5px;
                }

                svg {
                    width: 100%;
                    height: 100%;

                    path {
                        fill: white;
                    }

                    rect {
                        fill: transparent;
                        stroke: white;
                    }
                }

                @include mq($until: $viewport--sm) {
                    width: 30px;
                    height: 30px;
                }
            }
            
            @include mq($until: $viewport--sm) {
                bottom: -70px;
            }
        }
    }

    &--container {
        margin: 0 $spacing--normal;
        margin-bottom: 200px;
    }

    .figure {        
        &-1 {
            grid-area: image1;
            height: 420px;
            max-width: 370px;
            
            .image {
                max-width: inherit;
                height: inherit;
                height: 100%;
            }

            .shape {
                width: 216px;
                height: 88px;
                top: -96px;
                right: 0;
            }
        }
        
        &-2 {
            grid-area: image2;
            height: 420px;
            max-width: 520px;

            .image {
                max-width: inherit;
                height: inherit;
                height: 100%;
            }

            .shape {
                bottom: -96px;
                left: -96px;
            }
        }

        &-1, &-2 {
            position: relative;

            .shape {
                position: absolute;
            }
        }
    }
    
    .text {
        grid-area: text;
        font-family: $font-base;
        margin-top: 60px;
        font-size: 20px;
        line-height: 30px;
        max-width: 360px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    @include mq($until: 1400px) {
        &--container {
            margin-left: 30px;
            margin-right: 30px;
        }
    }

    @include mq($until: 1060px) {
        grid-template-columns: 430px auto 330px;                
    }
   
    @include mq($until: 850px) { 
        grid-template-columns: auto;
        grid-template-rows: repeat(2, auto);
        grid-template-areas: 
        'image2'
        'text'
        'image1';       
        grid-row-gap: 40px;

        .figure {
            &-2, &-1 {
                max-width: 360px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
            }

            &-1 {
                display: none;
            }
        }
    }

    @include mq($until: $viewport--md) {
        .text {
            font-size: 18px;
            line-height: 26px;
        }

        &--container {
            margin-bottom: 140px;
        }
    }
    
    @include mq($until: $viewport--sm) {
        .text {
            font-size: 16px;
            line-height: 24px;
            margin-top: 20px;
        }

        .figure {
            &-2 {
                width: 100%;

                .shape {
                    bottom: -52px;
                    left: -52px;
                }
            }
        }

        &--container {
            margin-bottom: 80px;
        }
    }
}