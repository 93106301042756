.slider {
    display: flex;
    align-items: center;
    margin-left: $spacing--normal;  

    &-left {
        min-width: 344px;
        max-width: 344px;
        margin-right: 200px;
        position: relative;

        .image {
            max-width: 344px;
            height: 424px;
        }

        .slick {
            &-list {
                overflow: visible;
            }
        }

        .shape {
            position: absolute;

            &:first-of-type {
                top: -96px;
                right: -96px;
            }
            
            &:last-of-type {
                width: 88px;
                bottom: -80px;
                left: -96px;
            }
        }
    }

    &-right {
        max-width: 460px;
        margin-top: -80px;
        
        .slick {
            &-arrow {
                position: absolute;
                bottom: -70px;
                cursor: pointer;

                &:nth-of-type(2) {
                    left: 50px;
                }

                path {
                    transition: fill .2s ease;
                }

                rect {
                    transition: stroke .2s ease, fill .2s ease;
                }

                &:hover {
                    path {
                        fill: $cc-white;
                    }

                    rect {
                        stroke: var(--purple);
                        fill: var(--purple);
                    }
                }
            }            
        }

        .info {
            .subtitle {
                margin-bottom: 10px;
            }

            .title {
                margin-bottom: 30px;
            }            
        }
    }

    @include mq($until: 1200px) {
        &-left {
            margin-right: 130px;
        }
    }
    
    @include mq($until: 1060px) {
        &-left {
            margin-right: 70px;
        }
    }
    
    @include mq($until: 920px) {
        padding: 0 40px;
        justify-content: center;

        &-left {
            display: none;
        }
    }

    @include mq($until: $viewport--sm) {
        max-width: 380px;

        .slick {
            &-slide {
                padding: 0 20px;
            }

            &-arrow {
                left: 20px;
                
                &:nth-of-type(2) {
                    left: 70px;
                }
            }
        }
    }

    @include mq($until: 520px) {
        &-right {
            max-width: 360px;
        }
    }

    @include mq($until: 420px) {
        &-right {
            max-width: 320px;
        }
    }

    @include mq($until: 360px) {
        &-right {
            max-width: 280px;
        }
    }
}