.Header {
    display: flex;
    margin-bottom: 200px;
    height: 100px;

    @include mq($from: $desktop-size) {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    @include mq($until: 1150px) {
        margin-bottom: 100px;
    }
   
    @include mq($until: $viewport--md) {
        margin-bottom: 50px;

        .hamburger {
            &-icon {
                &--container {
                    top: 40px !important;
                }
            }
        }
    }
    
    @include mq($until: $viewport--sm) {
        margin-bottom: 20px;
        height: 80px !important;

        &-logo {
            margin-top: 25px !important;
            margin-left: 25px !important;
        }

        .hamburger {
            &-icon {
                &--container {
                    right: 15px !important;
                    top: 20px !important;
                }
            }
        }
    }

    .hamburger {
        &-checkbox {
            display: none;

            &:checked {
                @include mq($until: $resp-nav) {                    
                    & ~ .Header-logo {
                        svg {
                            path {
                                fill: $cc-white;
                            }
                        }
                    }

                    & ~ .hamburger-icon--container,
                    & ~ .Header-logo,
                    & ~ .navigation {
                        position: fixed;
                    }
                }

                & ~ .navigation {
                    &-mobile {
                        opacity: 1;
                        transform: translateX(0);

                        @include mq($until: $resp-nav) {
                            .primary-link {
                                display: flex;
                            }
                        }
                    }                        
                }

                & ~ .hamburger-icon--container {
                    span {
                        background-color: $cc-white;
                        
                        &:first-child {
                            transform: translateY(3px) rotate(45deg);
                        }
                        
                        &:last-child {
                            transform: translateY(-3px) rotate(-45deg);
                        }
                    }
                }
            }
        }

        &-icon {
            width: 12px;
            height: 8px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            
            &--container {
                position: absolute;
                right: 30px;
                top: 65px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                z-index: 10000;
            }

            span {
                width: 100%;
                height: 2px;
                background-color: $cc-black;
                transition: transform .2s ease;
            }
        }
    }

    &-nav {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &-logo {
        height: 22px;
        margin-top: 25px;
        margin-left: 40px;
        position: relative;
        z-index: 1;

        svg {
            path {
                transition: fill .2s ease;
            }
        }

        @include mq($until: $viewport--sm) {
            height: 20px;
            max-width: 120px;

            svg {
                max-width: inherit;
                height: inherit;
            }
        }
    }

    .navigation {
        &-primary, &-mobile {
            transition: opacity .2s ease, transform .2s ease;

            ul {
                display: flex;
                margin-bottom: 0;
                padding-left: 0;
                
                li {
                    list-style: none;
                    margin-bottom: 0;
                    text-transform: lowercase;

                    &.active {
                        font-weight: bold;
                    }
                }                                 
            }
        }

        &-utility {
            * {
                text-transform: lowercase;
                font-size: 12px;
                line-height: 8px;
                color: white;
            }

            .line {
                background-color: white;
            }
            
            &.background {
                &-blue {
                    background-color: var(--blue);
                }

                &-green {
                    background-color: var(--green);
                }

                &-orange {
                    background-color: var(--orange);
                }           
            }

            @include mq($until: $viewport--sm) {
                display: none !important;
            }
        }

        &-primary {
            margin-right: 80px;
            margin-top: 25px;

            ul {
                li {
                    a {
                        &:hover {
                            font-weight: bold;
                        }
                    }
                }
                
                li + li {
                    margin-left: 40px;
                }
            }

            @include mq($until: $resp-nav) {
                opacity: 0;
                pointer-events: none;
            }
        }

        &-mobile {
            transform: translateX(100vw);
            opacity: 0;            
            background-color: var(--purple);
            width: 400px;
            position: absolute;
            right: 0;
            top: 0;
            min-height: 110px;
            padding: 30px;
            padding-top: 35px;
            padding-right: 80px;
            transition: width .2s ease, height .2s ease, transform .2s ease, opacity .2s ease;

            &.background {
                &-blue {
                    background-color: var(--blue);
                }

                &-green {
                    background-color: var(--green);
                }

                &-orange {
                    background-color: var(--orange);
                }           
            }

            .primary-link {
                display: none;
            }

            * {
                color: white;
            }

            .top {
                display: flex;
                justify-content: space-between;
            }

            .pages {
                display: flex;
                flex-direction: column;
                margin-right: 20px;

                li + li {
                    margin-top: 10px;
                }
            }

            .languages {
                display: flex;

                li {
                    list-style: none;
                    
                    &.active {
                        a {
                            font-weight: bold;
                        }
                    }

                    &:not(:last-of-type) {
                        margin-right: 10px;
                    }
                }
            }

            .follow-us {
                margin-top: 50px;

                li {
                    margin-right: 20px;

                    span {
                        text-transform: none;
                        font-weight: 600;
                    }
                }
            }

            @include mq($until: $resp-nav) {
                width: 100%;
                position: absolute;
                height: 100vh;
                padding-right: 30px;
                
                .top {
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    
                    .languages {
                        position: absolute;
                        bottom: 30px;
                        left: calc(50% - 40px);
                    }

                    .pages {
                        margin-right: 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        > li {
                            text-align: center;

                            a {
                                font-size: inherit;
                            }

                            &.primary-link {
                                font-size: 20px;

                                &.last-link {
                                    margin-bottom: 40px;
                                }
                            }

                            &:not(.primary-link) {
                                font-size: 15px;                             
                            }
                        }
                    }
                }

                .follow-us {
                    display: none;
                }
            }
        }
    }
}