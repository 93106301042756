.cta-banner {    
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &.newsletter-success {
        justify-content: center;

        h2 {
            text-align: center;
        }

        .right {
            display: none;
        }
    }

    * {
        color: $cc-white;
    }
    
    &--container {
        padding: 140px 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 420px;
    }

    .right {
        max-width: 340px;

        .text {
            font-family: $font-secondary;
            font-size: 16px;
        }

        .cta {
            margin-top: 40px;

            &:after {
                content: url("data:image/svg+xml;charset=utf8,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.3058 15.6286C10.1308 15.6285 9.96298 15.5589 9.83933 15.435C9.71568 15.3112 9.64628 15.1433 9.64641 14.9683C9.64653 14.7933 9.71616 14.6256 9.83999 14.5019C9.96382 14.3783 10.1317 14.3089 10.3067 14.309H17.9466L15.3651 11.7275C15.2414 11.6039 15.172 11.4361 15.172 11.2612C15.172 11.0863 15.2414 10.9186 15.3651 10.7949C15.4888 10.6713 15.6565 10.6018 15.8314 10.6018C16.0063 10.6018 16.1741 10.6713 16.2977 10.7949L20.0048 14.502C20.1285 14.6257 20.198 14.7934 20.198 14.9683C20.198 15.1432 20.1285 15.311 20.0048 15.4346L16.2977 19.1417C16.1741 19.2654 16.0063 19.3349 15.8314 19.3349C15.6565 19.3349 15.4888 19.2654 15.3651 19.1417C15.2414 19.0181 15.172 18.8503 15.172 18.6754C15.172 18.5005 15.2414 18.3328 15.3651 18.2091L17.9466 15.6277L10.3058 15.6286Z' fill='white'/%3E%3Crect x='3.75' y='3.51465' width='22.5' height='22.5' stroke='white' stroke-width='1.5'/%3E%3C/svg%3E");
            }

            &:hover {
                color: $cc-white;                
            }
        }

        .newsletter {
            margin-top: 20px;
            
            form {
                position: relative;
                display: flex;
                flex-direction: column-reverse;

                .ff-form-errors {
                    display: none;
                }

                .ff-errors {
                    li {
                        list-style: none;
                        color: $cc-red;
                    }
                }

                label {
                    display: none;
                }

                button {
                    cursor: pointer;
                    position: absolute;
                    top: 5px;

                    &:after {
                        content: url("data:image/svg+xml;charset=utf8,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.3058 15.6286C10.1308 15.6285 9.96298 15.5589 9.83933 15.435C9.71568 15.3112 9.64628 15.1433 9.64641 14.9683C9.64653 14.7933 9.71616 14.6256 9.83999 14.5019C9.96382 14.3783 10.1317 14.3089 10.3067 14.309L17.9466 14.309L15.3651 11.7275C15.2414 11.6039 15.172 11.4361 15.172 11.2612C15.172 11.0863 15.2414 10.9186 15.3651 10.7949C15.4888 10.6713 15.6565 10.6018 15.8314 10.6018C16.0063 10.6018 16.1741 10.6713 16.2977 10.7949L20.0048 14.502C20.1285 14.6257 20.198 14.7934 20.198 14.9683C20.198 15.1432 20.1285 15.311 20.0048 15.4346L16.2977 19.1417C16.1741 19.2654 16.0063 19.3349 15.8314 19.3349C15.6565 19.3349 15.4888 19.2654 15.3651 19.1417C15.2414 19.0181 15.172 18.8503 15.172 18.6754C15.172 18.5005 15.2414 18.3328 15.3651 18.2091L17.9466 15.6277L10.3058 15.6286Z' fill='white'/%3E%3C/svg%3E");
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        left: 0;
                    }
                }

                .freeform-row {
                    margin: 0;
                    padding: 0;
                    position: relative;

                    .freeform-column {
                        padding: 0;

                        &:nth-of-type(1) {
                            width: 100%;

                            input {
                                font-size: 16px;
                                padding: 10px 0;
                                background-color: unset;
                                border: none;
                                border-bottom: 1px solid white;
                                appearance: none;
                                outline: none;
                                font-family: $font-base;

                                &::placeholder {
                                    color: rgba(255, 255, 255, .5);
                                }
                            }
                        }

                        &:nth-of-type(2) {
                            position: absolute;
                            right: 10px;
                            width: 20px;
                        }
                    }
                }
            }
        }
    }

    @include mq($until: $viewport--md) {
        flex-direction: column;
        align-items: center;
        text-align: center;

        &--container {
            padding: 80px 40px;
        }

        .right {
            margin-top: 40px;

            .cta {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}