.HomePage {
    .products {
        .shape {
            left: -80px;
            right: unset;
        }
    }

    .textblock {
        flex-direction: row-reverse;        

        &-container {
            margin-top: 300px;
        }

        .info {
            max-width: 380px;
            margin-right: 20px;
        }

        .shape {
            &:nth-of-type(2) {
                left: -88px;
                right: unset;
            }
        }

        @include mq($until: $viewport--md) {
            flex-direction: column-reverse;
            align-items: center;
            justify-content: flex-start;   
            
            &-container {
                margin-top: 140px;
            }

            .info {
                margin-top: 150px;
            }
        }

        @include mq($until: $viewport--sm) {
            .info {
                margin-top: 100px;
                margin-right: 0;
            }

            .figure {
                max-width: 260px;
            }
        }
    }
}