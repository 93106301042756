
// ============================================
// VENDOR: SLICK
// ============================================

@import '../../../node_modules/slick-carousel/slick/slick.scss';
@import '../../../node_modules/slick-carousel/slick/slick-theme.scss';

.slick-dotted {
    &.slick-slider {
        @apply mb-0;
    }
}

.slick-slider {
    @apply mb-0;
    .slick-slide {
        &:focus {
            @apply outline-none;
        }
    }
}

.slick-track,
.slick-list {
    @apply h-full;
}

.slick-dots{
    @apply static text-left;
}
