.image {
    overflow: hidden;

    &.parallax-container {        
        position: relative;
        width: 100%;
        overflow: hidden;   
        
        .parallax-content {
            background-size: cover;
            position: relative;
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            height: 120% !important;
        }
    }

    &.fullscreen {
        height: 700px;   

        @include mq($from: $viewport--xxl) {
            height: 820px;
        }
        
        @include mq($until: $viewport--sm) {            
            height: 360px;
        }
    }

    img {
        max-width: inherit;
        max-height: inherit;
        transition: width .4s ease, height .4s ease;
    }
}