.HistoryPage {
    .page-header {
        display: grid;
        grid-template-columns: 480px auto;
        grid-column-gap: 200px; 
    }

    .history-container {
        display: grid;
        grid-template-columns: 480px auto;
        justify-content: flex-start;
        grid-column-gap: 200px;
        margin-top: 200px;
        padding: 0 20px;

        .row {
            .textblock {
                h2 {
                    margin-top: 30px;
                }
            }
        }

        .left {
            .row {
                text-align: right;

                .textblock {
                    justify-content: flex-end;
                }

                .image-container {
                    margin-left: auto;
                }
            }
        }

        .right {
            margin-top: 80px;
        }
    }

    @include mq($until: 1700px) {
        .page-header {            
            grid-column-gap: 120px;
        }
        
        .history-container {                               
            grid-column-gap: 200px;
        }
    }
   
    @include mq($until: 1300px) {
        .page-header {    
            max-width: unset;
            width: 100%;
            grid-template-columns: 480px minmax(200px, 350px);  
            padding: 0 30px;          
        }
        
        .history-container {    
            grid-column-gap: 0;
            grid-template-columns: 340px auto;                           
            max-width: unset;
            width: 100%;
            justify-content: space-between;
            padding: 0 30px; 
        }
    }
    
    @include mq($until: 1100px) {
        .page-header {    
            display: flex;
            flex-direction: column;

            .text {
                margin-top: 40px;
            }
        }        
    }
    
    @include mq($until: 1000px) {
        .history-container {
            display: flex;
            margin-top: 90px;
        }
    }
   
    @include mq($until: $viewport--sm) {
        .page-header {
            padding: 0 10px;
        }
        
        .history-container {
            padding: 0;
        }
    }
}