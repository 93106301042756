.DurabilityPage {
    .page-header {
        margin-left: $spacing--xxl;
        margin-bottom: 180px;

        .text {
            max-width: 500px;
        }
    
        > div {
            margin-top: 20px;
        }
    }

    .video {
        &-container {
            outline: 6px solid $cc-white;
            background-color: $cc-white;

            &--base {
                margin: 0 $spacing--normal;
                margin-bottom: 150px;
                position: relative;               

                .shape {
                    position: absolute;
                    z-index: -1;

                    &:nth-of-type(1) {
                        right: -88px;
                        top: -88px;
                    }
                    
                    &:nth-of-type(2) {
                        left: -94px;
                        bottom: -94px;
                    }
                }

                img {
                    border-top: 6px solid $cc-white;
                    border-right: 6px solid $cc-white;
                }
            }
        }
    }

    .durability {
        &-section {
            display: flex;
            justify-content: space-between;

            .column {
                &.left {
                    margin-top: 88px;
                }
            }
        }
    }

    @include mq($from: 1800px) {
        .page-header {
            padding-left: 120px;
        }
        
        .page-header, .video-container--base {            
            margin-left: auto;
            margin-right: auto;
            max-width: 1400px;
        } 
    }

    @include mq($until: 1800px) {        
        .page-header, .video-container--base {            
            margin-left: auto;
            margin-right: auto;                        
        } 

        .page-header {
            max-width: 960px;
        }

        .video {
            height: 480px;

            &-container {
                &--base {
                    max-width: 920px;
                }
            }
        }
    }

    @include mq($until: 1400px) {
        .page-header {
            margin-left: 200px;
            margin-right: 200px;
            margin-bottom: 140px;
        }
    }
    
    @include mq($until: 1160px) {
        .video {
            &-container {
                &--base {
                    margin: 0 110px;
                    margin-bottom: 240px;
                }
            }
        }
    }
    
    @include mq($until: 960px) {
        .page-header {
            margin-left: 100px;
            margin-right: 100px;
            margin-bottom: 100px;
        }
    }
    
    @include mq($until: $viewport--md) {
        .page-header {
            margin-left: 60px;
            margin-right: 60px;           
        }

        .video {
            height: 360px;

            &-container {
                &--base {
                    margin: 0 50px;
                    margin-bottom: 180px;
                }
            }
        }
    }
    
    @include mq($until: $viewport--sm) {
        .page-header {
            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 60px;
        }
        
        .video {
            height: 280px;

            &-container {
                &--base {
                    margin: 0 20px;
                    margin-bottom: 120px;

                    .shape {
                        &:nth-of-type(1) {
                            right: -52px;
                            top: -52px;
                        }
                        
                        &:nth-of-type(2) {
                            left: -28px;
                            bottom: -28px;
                        }
                    }
                }
            }
        }
    }
}