.blog {
    &-content {
        transform: translateY(-200px);

        &.amount-1 {
            .shape {
                &:nth-of-type(1) {
                    left: -88px;
                    right: unset;
                }
                
                &:nth-of-type(2) {
                    left: 0;
                    right: unset;
                }
            }
        }       

        .blog {
            &-items {
                display: grid;
                grid-template-columns: repeat(2, 50%);                
                grid-row-gap: 80px;         
            }

            &-item {
                color: $cc-black;

                &:nth-of-type(odd) {
                    margin-right: 30px;
                }
                
                &:nth-of-type(even) {
                    margin-left: 30px;
                }
                
                &--image {
                    height: 400px;
                    overflow: hidden;
                    position: relative;
                    
                    * {
                        height: inherit;
                    }

                    .image {
                        position: relative;
                        z-index: -2;
                        
                        img {
                            transition: transform .2s ease;
                        }
                    }
                    
                    &:before, &:after {
                        position: absolute;
                        left: 0;
                        top: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: opacity .2s ease;
                        opacity: 0;
                    }

                    &:before {
                        content: url("data:image/svg+xml;charset=utf8,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.5754 22.8629C16.294 22.8631 16.0241 22.9751 15.8252 23.1743C15.6263 23.3735 15.5147 23.6435 15.5149 23.9249C15.5151 24.2064 15.6271 24.4762 15.8263 24.6751C16.0254 24.874 16.2955 24.9856 16.5769 24.9854L28.8649 24.9854L24.7129 29.1374C24.514 29.3363 24.4023 29.6061 24.4023 29.8874C24.4023 30.1687 24.514 30.4385 24.7129 30.6374C24.9118 30.8363 25.1816 30.9481 25.4629 30.9481C25.7442 30.9481 26.014 30.8363 26.2129 30.6374L32.1754 24.6749C32.3743 24.476 32.4861 24.2062 32.4861 23.9249C32.4861 23.6436 32.3743 23.3738 32.1754 23.1749L26.2129 17.2124C26.014 17.0135 25.7442 16.9018 25.4629 16.9018C25.1816 16.9018 24.9118 17.0135 24.7129 17.2124C24.514 17.4113 24.4023 17.6811 24.4023 17.9624C24.4023 18.2437 24.514 18.5135 24.7129 18.7124L28.8649 22.8644L16.5754 22.8629Z' fill='white'/%3E%3Crect x='42.1758' y='42.3188' width='36.6017' height='36.2405' transform='rotate(-180 42.1758 42.3188)' stroke='white' stroke-width='2'/%3E%3C/svg%3E");
                        width: 50px;
                        height: 50px;
                        top: calc(50% - 25px);
                        left: calc(50% - 25px);
                    }

                    &:after {
                        content: '';
                        background-color: rgba(0,0,0, .35);
                        width: 100%;
                        height: 100%;
                        z-index: -1;
                    }
                }

                &--text {
                    display: flex;
                    justify-content: space-between;                    
                    margin-top: 15px;

                    .left {
                        .date {
                            margin-right: 5px;
                            opacity: .2;
                        }

                        * {
                            font-size: 20px;
                        }
                    }

                    .right {
                        svg {
                            path {
                                transition: fill .2s ease;
                            }

                            rect {
                                transition: stroke .2s ease, fill .2s ease;
                            }
                        }
                    }
                }

                &:hover {    
                    .blog {
                        &-item {
                            &--image {
                                img {
                                    transform: scale(1.05);
                                }

                                &:before, &:after {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }

        .shape {
            position: absolute;

            &:nth-of-type(1) {
                right: -88px;
                top: -98px;
            }
            
            &:nth-of-type(2) {
                width: 88px;
                right: 0;
                top: -260px;
            }
        }
        
        &--container {
            margin-top: 200px;
            background-color: $cc-lightGrey;
        }

        @include mq($until: 960px) {            
            &--container {
                padding: 0 20px;    
            }

            .blog {
                &-items {
                    grid-row-gap: 40px;
                }

                &-item {
                    &:nth-of-type(odd) {
                        margin-right: 10px;
                    }
                    
                    &:nth-of-type(even) {
                        margin-left: 10px;
                    }
                }
            }
        }

        @include mq($until: $viewport--md) {
            .shape {
                display: none;
            }

            .blog {
                &-items {
                    grid-template-columns: minmax(280px, 400px);
                    justify-content: center;
                }

                &-item {
                    &:nth-of-type(odd),
                    &:nth-of-type(even) {
                        margin-right: 0;
                        margin-left: 0;
                    }

                    &--text {
                        .right {
                            margin-left: 20px;
                        }
                    }
                }
            }
        }

        @include mq($until: $viewport--sm) {
            transform: translateY(0px);
            padding-top: 0;
            padding-bottom: 0;

            &--container {
                margin-top: 80px;
            }

            .blog {
                &-item {
                    &--image {
                        height: 250px;
                    }
                }

                &-items {
                    margin-top: 180px;
                    transform: translateY(-125px);
                }
            }
        }
    }
}