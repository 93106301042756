.home-header {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -200px;
    position: relative;
    padding-left: 180px;    
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: inherit;
    
    &--container {
        min-height: calc(100vh - 100px);
        margin-bottom: 100px;

        @include mq($until: 1050px) {
            min-height: calc(100vh + 90px);
        }
        
        @include mq($until: $viewport--sm) {
            min-height: unset;
        }
    }

    &--text {
        max-width: 26vw;
        width: 100%;
        margin-right: 60px;

        .cta {
            margin-top: 40px;
        }

        @include mq($until: $viewport--lg) {
            max-width: unset;
        }
    }

    &--image {
        position: relative; 
        width: 100%;      

        .shape {
            &-container {
                position: absolute;
    
                &:nth-of-type(1) {
                    top: 8px;
                    left: -94px;                    

                    .custom-fade-in {
                        animation-delay: 3.1s;
                    }
                }
                
                &:nth-of-type(2) {   
                    left: -81px;
                    bottom: -81px;
                    z-index: -1;

                    .custom-fade-in {
                        animation-delay: 2.8s;
                    }
                }
            }
        }

        .image {
            width: 100%;
            height: 50vw;
            max-height: 70vh;
            border: 8px solid white;
            background-color: white;

            .parallax-content {
                height: 120%;
                transition: width .4s ease, transform .4s ease;
            }
        }

        .splide-nav {
            position: absolute;
            right: 0;
            bottom: -80px;
            display: flex;
            justify-content: center;
            padding: 20px;
            margin-right: .5rem;

            &.background {
                &-blue {
                    background-color: var(--blue);
                }
    
                &-green {
                    background-color: var(--green);
                }
    
                &-orange {
                    background-color: var(--orange);
                }           
                
                &-yellow {
                    background-color: var(--yellow);
                }
    
                &-purple {
                    background-color: var(--purple);
                }
    
                &-pink {
                    background-color: var(--pink);
                }  
            }

            button {
                cursor: pointer;
                outline: none;
                width: 40px;
                height: 40px;

                &.next {
                    margin-left: 5px;
                }

                svg {
                    width: 100%;
                    height: 100%;

                    path {
                        fill: white;
                    }

                    rect {
                        fill: transparent;
                        stroke: white;
                    }
                }

                @include mq($until: $viewport--sm) {
                    width: 30px;
                    height: 30px;
                }
            }
            
            @include mq($until: $viewport--sm) {
                bottom: -70px;
            }
        }
    }

    .aligned-shape-container {
        position: absolute;
        left: 0;
        top: 0;

        .custom-fade-in {
            animation-delay: 2.5s;
        }
    }   

    @include mq($until: $viewport--md) {
        padding-left: 40px;
    }
   
    @include mq($from: $viewport--sm) {
        &--image {
            .shape {
                &-container {
                    &:nth-child(3),
                    &:nth-child(4) {
                        display: none;
                    } 
                }
            }
        }
    }

    @include mq($until: $viewport--sm) {
        padding: 0 60px;
        padding-bottom: 100px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 0;

        &--container {
            margin-bottom: 100px;
        }

        &--image {
            transform: translateX(0);
            right: unset;            
            position: relative;
            margin-top: 50px;
            width: 100%;
            max-height: 320px;

            .image {
                width: 100%;
                height: 325px;
            }

            .shape {
                &.lines {
                    width: 180px;
                    bottom: -40px;
                    right: 0px;
                    background-image: repeating-linear-gradient(-45deg, #000000, #000000 5px, transparent 5px, transparent 15px);
                }
                
                &-container {
                    &:nth-child(2) {
                        left: -88px;
                        bottom: -88px;
                    }  

                    &:nth-child(3) {
                        right: -32px;
                        top: 8px;

                        .shape {
                            width: 40px;
                            height: 40px;
                        }
                    }

                    &:nth-child(4) {
                        right: -72px;
                        top: 48px;

                        .shape {
                            width: 40px;
                            height: 80px;
                        }
                    } 
                }
            }

        }

        &--text {
            padding-top: 40px;
            margin-left: 0;
            margin-right: auto;

            .cta {
                margin-top: 20px;
            }

            @media only screen and (max-width: 600px) and (max-height: 700px) {
                padding-top: 0;
            }
        }
    }
}