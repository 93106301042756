/* Colors: Theme */

// Lay-out
$primary-color:                     currentColor !default;
$secondary-color:                   currentColor !default;
$tertiary-color:                    currentColor !default;

$text-color:                        $black !default;
$bg-color:                          $white !default;
$border-color:                      $cc-grey !default;

$header-bg-color:                   $grey !default;
$footer-bg-color:                   $grey !default;

// States
$success-text-color:                scale-color($green, $saturation: -50%) !default;
$success-bg-color:                  scale-color($green, $lightness: 90%, $saturation: -25%) !default;
$success-border-color:              scale-color($success-bg-color, $lightness: -5%) !default;

$info-text-color:                   scale-color($blue, $saturation: -50%) !default;
$info-bg-color:                     scale-color($blue, $lightness: 90%, $saturation: -25%) !default;
$info-border-color:                 scale-color($info-bg-color, $lightness: -5%) !default;

$warning-text-color:                scale-color($orange, $saturation: -50%) !default;
$warning-bg-color:                  scale-color($orange, $lightness: 90%, $saturation: -25%) !default;
$warning-border-color:              scale-color($warning-bg-color, $lightness: -5%) !default;

$danger-text-color:                 scale-color($red, $saturation: -50%) !default;
$danger-bg-color:                   scale-color($red, $lightness: 90%, $saturation: -25%) !default;
$danger-border-color:               scale-color($danger-bg-color, $lightness: -5%) !default;
