.AboutPage {
    .page {
        &-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-bottom: 200px;
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;

            .text {
                margin-top: 30px;
            }

            .cta {
                margin-top: 40px;
            }

            @include mq($until: $viewport--md) {
                margin-bottom: 120px;
            }
            
            @include mq($until: $viewport--sm) {
                margin-bottom: 80px;
    
                .text {
                    margin-top: 20px;
                }
            }
        }
    }

    .intro-block {
        &--container {
            margin-bottom: 200px;
        }

        @include mq($until: $viewport--md) {
            .text {
                font-size: 18px;
                line-height: 26px;
            }
    
            &--container {
                margin-bottom: 140px;
            }
        }
        
        @include mq($until: $viewport--sm) {
            .text {
                font-size: 16px;
                line-height: 24px;
            }
    
            &--container {
                margin-bottom: 80px;
            }
        }
    }

    .image-container {
        margin: 0 $spacing--viewport;
        margin-bottom: 200px;

        &.mobile {
            display: none;
            margin-bottom: 160px;
        }

        @include mq($until: $viewport--md) {
            margin-bottom: 140px;
        }
                
        @include mq($until: $viewport--sm) {
            margin-bottom: 80px;

            &.desktop {
                display: none;
            }

            &.mobile {
                display: flex;
            }
        }
    }

    .textblock-container {
        .textblock {
            .shape {
                &:nth-of-type(1) {                    
                    right: unset;
                    left: -94px;
                }

                &:nth-of-type(2) {                    
                    width: 176px;
                    height: 88px;
                    background-image: repeating-linear-gradient(-45deg, #000000, #000000 5px, white 5px, white 19px);
                    background-color: unset !important;
                    bottom: -94px;
                    right: 0;              
                }
            }

            @include mq($until: $viewport--sm) {
                flex-direction: column-reverse;

                .info {
                    margin-bottom: 0;
                    margin-top: 140px;
                }
            }
        }
    }

    @include mq($from: $viewport--sm) {
        .textblock-container {
            .textblock {
                flex-direction: row-reverse;        
            }
        }
    }

    .tussentext {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 240px;
        padding: 0 20px;
        max-width: 920px;
        margin-left: auto;
        margin-right: auto;

        > .text {
            margin-top: 20px;
        }

        @include mq($until: $viewport--md) {
            margin-bottom: 240px;
        }
                
        @include mq($until: $viewport--sm) {
            margin-bottom: 140px;
        }
    }

    .slider {
        margin-bottom: 300px;

        @include mq($until: $viewport--md) {
            margin-bottom: 220px;
        }
                
        @include mq($until: $viewport--sm) {
            margin-bottom: 160px;
        }
    }

    .contact-section {
        display: flex;
        
        margin: 0 $spacing--viewport;
        padding: 0 $spacing--normal;

        &--container {
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            max-width: 1100px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        .content {
            margin-bottom: 120px;
            display: flex;
            flex-direction: column;
            align-items: center;

            h2 {
                text-align: center;
            }

            .cta {
                margin-top: 30px;
            }
        }

        @include mq($until: 1360px) {
            padding: 180px 100px;
        }
        
        @include mq($until: 1000px) {
            padding: 100px 60px;
        }
        
        @include mq($until: $viewport--md) {
            flex-direction: column;
            padding: 80px 40px;

            .content {
                margin-bottom: 60px;
            }
        }
        
        @include mq($until: $viewport--sm) {            
            padding: 80px 20px;
            &--container {
                flex-direction: column;
                justify-content: flex-start;
            }
        }
    }
}