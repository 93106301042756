.ThanksPage {
    .page-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 320px;

        .text {
            margin-top: 40px;
        }
    }
}