.custom {
    &-slider {
        justify-content: center;
        padding-top: 300px;
        margin-top: -300px;
        padding-bottom: 88px;

        @include mq($until: 1300px) {
            padding-top: 200px;
            margin-top: -200px;
        }

        @include mq($until: 1100px) {
            padding-top: 120px;
            margin-top: -120px;
        }
       
        @include mq($until: $viewport--md) {
            padding-top: 80px;
            margin-top: -80px;
        }

        &.no-slider {
            .right {
                .info {
                    margin-bottom: 0 !important;
                }
            }
        }

        .slides {
            &-actual {
                display: none;
            }
    
            &-cloned {
                display: grid;
                grid-template-columns: auto;
                grid-template-rows: auto;
                grid-template-areas: 'content';
                align-items: center;
                width: 100%;

                > * {
                    grid-area: content;

                    &.animatein {
                        .left {                        
                            .shape, figure > div {
                                transition: width .2s ease;
                                transition-delay: 0s;
                            }
                        }

                        .right {
                            width: 380px;

                            .info {
                                transition: opacity .2s ease, transform .2s ease;
                            }
                        }
                    
                        &.firing {
                            .left {
                                .shape, figure > div {
                                    width: 0px !important;    
                                }
                            }

                            .right {
                                .info {
                                    opacity: 0;
                                    transform: translateX(-100px);
                                }
                            }
                        }
                    }
                }
            }
        }

        .current-slide {
            display: flex;
            align-items: center;
            position: relative;
            flex-direction: row-reverse;
            justify-content: space-between;

            .left {
                position: relative;

                figure {
                    width: 520px;
                    height: 420px;
                    border: 8px solid white;
                    background-color: white;
                }                

                .shape {
                    position: absolute;
        
                    &:first-of-type {
                        left: -78px;
                        bottom: -78px;
                    }
                }

                .custom-slider--buttons {
                    position: absolute;
                    right: 0;
                    bottom: -80px;
                    display: flex;
                    justify-content: center;
                    padding: 20px;
                    margin-right: 8px;
                    transition: background-color .4s ease;

                    &.background {
                        &-blue {
                            background-color: var(--blue);
                        }
            
                        &-green {
                            background-color: var(--green);
                        }
            
                        &-orange {
                            background-color: var(--orange);
                        }           
                        
                        &-yellow {
                            background-color: var(--yellow);
                        }
            
                        &-purple {
                            background-color: var(--purple);
                        }
            
                        &-pink {
                            background-color: var(--pink);
                        }  
                    }

                    button {
                        cursor: pointer;
                        outline: none;
        
                        &.next {
                            margin-left: 5px;
                        }
        
                        svg {
                            path {
                                fill: white;
                            }
        
                            rect {
                                fill: transparent;
                                stroke: white;
                            }
                        }
                    }
                }
            }

            .right {
                display: flex;
                flex-direction: column;

                .info {
                    margin-bottom: 50px;

                    .text {
                        margin-top: 10px;
                    }

                    .quote {
                        font-size: 20px;                
                        line-height: 35px;   
                        position: relative;
                        
                        &:before {
                            content: '\201C';
                            position: absolute;
                            top: -30px;
                            left: 0;
                            font-size: 50px;
                            color: $cc-black;
                            opacity: .2;
                        }
                    }

                    .author {
                        font-family: $font-secondary;
                    }
                }
            }
        }

        &--buttons {
            margin-top: 50px;
            margin-left: -5px;
            position: absolute;
            bottom: 0;
        }

        .slider {
            &-button {
                cursor: pointer;
                transition: opacity .4s ease;

                &.inactive {
                    pointer-events: none;
                    opacity: .6;
                }

                &--prev {
                    transform: rotate(180deg);
                }

                &--next {
                    transform: translate(-6px, 1px);
                }

                svg {
                    width: 40px;
                    height: 40px;

                    path {
                        transition: fill .4s ease;
                    }

                    rect {
                        transition: stroke .4s ease, fill .4s ease;
                        fill: rgba(255, 255, 255, 0);
                    }
                }

                &:hover {
                    svg {
                        path {
                            fill: $cc-white;
                        }
    
                        rect {
                            stroke: var(--purple);
                            fill: var(--purple);
                        }
                    }
                }

                &:focus {
                    outline: none;
                }
            }
        }

        @include mq($until: 1000px) {
            .current-slide {
                flex-direction: column;

                .right {
                    margin-left: 0;
                    margin-top: 160px;
                }
            }
        }
       
        @include mq($until: $viewport--sm) {
            padding-left: 30px;
            padding-right: 30px;

            .current-slide {
                
                .left, .right {
                    width: 100% !important;
                }

                .left {
                    .image {
                        width: 100%;
                        height: 300px;
                    }

                    .shape {
                        &:nth-of-type(1) {
                            bottom: -40px;
                            left: -40px;
                        }
                    }
                }                
            }
        }
    }
}