.shape {
    &.lines {
        width: 176px;
        height: 80px;
        background-image: repeating-linear-gradient(-45deg, #000000, #000000 5px, white 5px, white 19px);
        background-color: unset !important;

        &.small-rect {
            width: 88px;
            height: 88px;    
        }
    }

    &.top-left-white {
        &.rect {
            right: -96px !important;
            bottom: -96px !important;
            width: 172px;
            height: 172px;
        }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 50%;
            height: 50%;
            background-color: white;
            z-index: 1;
        }
    }
    
    &.rect {
        width: 162px;
        height: 162px;
    }
    
    &.small-rect {
        width: 88px;
        height: 88px;
    }
    
    &.triangle {
        width: 162px;
        height: 162px;
        position: relative;
        
        &:before {
            content: '';
            width: 88px;
            height: 88px;
            left: 0;
            bottom: 0;
            position: absolute;
            background-color: white;
        }
    }

    &.dots {
        width: 80px;
        height: 88px;
        margin-left: 5px;
        margin-top: 8px;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='17' height='13' viewBox='0 0 17 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 3C6 4.65685 4.65685 6 3 6C1.34315 6 0 4.65685 0 3C0 1.34315 1.34315 0 3 0C4.65685 0 6 1.34315 6 3Z' fill='black'/%3E%3C/svg%3E");
        background-size: 12px;
    }

    &.custom-fade-in {
        opacity: 0;
        animation-name: fadeanimation;
        animation-duration: .5s;
        animation-fill-mode: forwards;        
        animation-timing-function: ease;
        
        @keyframes fadeanimation {
            from {
                opacity: 0;
            }

            to {
                opacity: 1;
            }
        }
    }

    @include mq($until: $viewport--sm) {
        &.lines {
            width: 88px;
            height: 44px;
        }

        &.rect {
            width: 88px;
            height: 88px;
        }

        &.small-rect {
            width: 44px;
            height: 44px;
        }

        &.triangle {
            width: 88px;
            height: 88px;

            &:before {
                width: 44px;
                height: 44px;
            }
        }
        
        &.dots {
            width: 40px;
            height: 42px;
        }
    }
}