/* Cookie */
.CookieConsent {
  background-color: white;
  color: rgb(31, 30, 30);
  transition: transform .2s ease, visibility .2s ease, max-width .2s ease;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: absolute;
  bottom: 0;
  z-index: 900;

  max-width: 380px;
  right: 20px;
  bottom: 20px;
  padding: 30px;
  left: 20px;  

  a {
    text-decoration: underline;
  }

  &.customization {
    pointer-events: none;      
    
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(31, 30, 30, .6);
    }
  }

  &-custom {
    background-color: white;
    width: 500px;
    padding: 20px 25px;
    z-index: 1000;

    &.hidden {
      display: none;
    }

    .title {
      font-weight: 600;
      margin-bottom: 10px;
    }

    .text {
      font-size: 12px;
    }

    .accept-compliance-cookie {
      margin-top: 10px;
      
    }

    .choices {
      &-container {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #1f1e1e33;
      }

      .checkbox + .checkbox {
        margin-top: 20px;
      }
  
      .checkbox {
        &.required {
          .input {
            pointer-events: none;
            opacity: .6;
            filter: grayscale(1);
          }
        }
  
        .input {
          .fake-box {
            width: 15px;
            height: 15px;
            border: 2px solid rgb(31, 30, 30);
            transition: border-color .2s ease, background-color .2s ease;
          }
  
          label {
            display: flex;
            align-items: center;
            cursor: pointer;
  
            > .fake-box {
              margin-right: 5px;
            }
  
            span {
              font-size: 14px;
              line-height: 1;
            }
          }
  
          input {
            display: none;
  
            &:checked {
              & ~ label > .fake-box {
                border-color: rgb(31, 30, 30);
                background-color: rgb(31, 30, 30);
              }
            }
          }
        }
  
        .info {
          display: none;
          font-size: 12px;
          margin-top: 5px;

          &-container {
            margin-top: 5px;
          }
  
          &-trigger {
            font-size: 14px;
            border-bottom: 1px solid white;
            cursor: pointer;
            transition: opacity .2s ease;
  
            &:hover {
              opacity: .7;
            }
          }
  
          &-checkbox {
            display: none;   
            
            &:checked {
              & ~ .info {
                display: flex;
              }            
              
              & ~ .info-trigger {
                display: none;
              }
            }
          }
        }
      }
    }

    .customize-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;

      .customize-back-btn {
        margin-top: 10px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }

  &-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(31, 30, 30, .6);
    z-index: 9998;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity .2s ease;
    pointer-events: none;

    &.is-active {
      opacity: 1;
      pointer-events: all;
    }
  }

  .normal-buttons, .customize-buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;      

    &.hidden {
      display: none;
    }
  }    

  .customize-container {
    margin-top: 30px;

    &.hidden {
      display: none;
    }      
  }
}

.CookieConsent:not(.is-active) {
  transform: translateY(100%);
  visibility: hidden;
}

.CookieConsent > .Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 16px;
  font-weight: 400;
}

.Accept, .customize-btn {
  cursor: pointer;   
  line-height: 1;
  width: 100%;
  border: 1px solid rgb(31, 30, 30);
  color: white;
  font-size: 14px;
  padding: 10px 12px;
  background-color: rgb(31, 30, 30);
  text-align: center;
  font-weight: 600;
  transition: background-color .2s ease, color .2s ease;

  &:hover {
    color: rgb(31, 30, 30);
    background-color: white;
  }
}

.customize-btn {
  margin-top: 10px;
}

