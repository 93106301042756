.PrivacyPagesPage {
    h1 {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
       

        @include mq($until: 860px) {
            padding: 0 30px;
        }
    }

    .page {
        &-content {
            &--container {
                margin-top: 90px;
                margin-bottom: 90px;
                padding: 0 30px;

                > * {
                    margin: 90px auto;

                    &:first-child {
                        margin-top: 0 !important;
                    }
                    
                    &:last-child {
                        margin-bottom: 0 !important;
                    }                    
                }
            }

            &--text {
                max-width: 800px;
                margin-left: auto;
                margin-right: auto;

                h3 {
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: bold;
                    margin-bottom: 15px;
                }

                .text {
                    font-family: $font-secondary;
                    font-size: 16px;
                }

                ul {
                    padding-left: 10px;
                    margin-bottom: 0;
                    margin-top: 10px;

                    li {
                        list-style: none;
                        position: relative;
                        
                        &:before {
                            content: '';
                            width: 5px;
                            height: 1px;
                            position: absolute;
                            left: -10px;
                            background-color: $cc-black;
                            top: 12px;
                        }
                    }
                }
            }            
            
            @include mq($until: $viewport--md) {
                padding: 0 30px;
                
                &--container {
                    margin-top: 50px;
                    margin-bottom: 50px;

                    > * {
                        margin: 50px auto;

                        &.page-content {
                            &--image {
                                margin: 50px auto;
                            }

                            &--quote {
                                margin: 150px auto;
                            }
                        }
                    }
                }
            }
            
            @include mq($until: $viewport--sm) {
                .textblock {
                    margin-bottom: 0;
                }
            }
        }       
    }
}