.textblock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 300px;

    &.quote {
        .figure {
            .shape {                        
                left: -94px;
                right: unset;   
                
                &:nth-of-type(1) {
                    left: unset;
                    top: 0;
                }
            }
        }

        .info {
            font-size: 20px;                
            line-height: 35px;   
            position: relative;
            
            &:before {
                content: '\201C';
                position: absolute;
                top: -30px;
                left: 0;
                font-size: 50px;
                color: $cc-black;
                opacity: .2;
            }
        }
    }

    .info {
        display: flex;
        flex-direction: column;
        max-width: 320px;
        margin-right: 100px;

        h1 {
            &.outline {
                color: transparent;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: black;
            }
        }

        h2 {
            margin-bottom: 20px;
        }

        .text {
            margin-bottom: 30px;
        }

        .ctas {
            margin-top: -10px;
            
            .cta {
                margin-top: 10px;                

                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }
    }

    .figure {
        height: 420px;
        max-width: 350px;
        width: 100%;
        position: relative;

        &.square {
            height: 350px;
        }

        > .image {
            height: inherit;

            * {
                height: inherit;
            }
        }

        .image {
            outline: 6px solid $cc-white;
            position: relative;
            z-index: 1;
        }

        .shape {
            position: absolute;

            &:nth-of-type(1) {
                right: -94px;
                top: -94px;
            }
            
            &:nth-of-type(2) {
                right: -88px;
                bottom: -88px;
            }
        }
    }

    @include mq($until: 920px) {
        &.container {
            max-width: 800px;
            padding: 0 30px;
        }
        
        .info {
            max-width: 360px;
            margin-right: 20px;
            padding-left: 30px;
        }
    }
    
    @include mq($until: $viewport--md) {
        margin-bottom: 140px;
    }
    
    @include mq($until: $viewport--sm) {
        margin-bottom: 100px;
        
        &-container {
            margin-top: 100px;
        }

        .info, .figure {
            max-width: 320px;
            width: 100%;
        }
        
        .info {            
            padding-left: 0;
        }
        
        .figure {            
            height: 320px;

            .shape {
                &:nth-of-type(1) {
                    right: -52px;
                    top: -52px;
                }
                
                &:nth-of-type(2) {
                    left: -52px !important;
                    bottom: -52px;
                }
            }
        }
    }

    @include mq($until: $viewport--sm) {
        flex-direction: column;

        .info {
            margin-bottom: 60px;
            margin-right: 0;
        }
    }
}