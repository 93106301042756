.column {
    margin-bottom: 100px;
    
    .row {
        .textblock {
            margin-bottom: 0;

            .info {                
                margin-right: 0;                
            }
        }
    
        &:not(:first-of-type) {
            margin-top: 100px;
        }
        
        .image-container {
            &.shapes {
                margin-bottom: 200px;
                position: relative;
    
                .shape {
                    position: absolute;
                }
            }
    
            .image {
                width: 100%;
                height: 100%;
            }

            @include mq($until: 1000px) {
                &.portrait {
                    width: 280px !important;
                    height: 350px !important;
                }
    
                &:not(.portrait) {
                    width: 370px !important;
                    height: 240px !important;
                }
            }                    
        }
    }
    
    &.left {
        .row {
            .image-container {
                &.shapes {                            
                    .shape {    
                        &:nth-of-type(1) {
                            bottom: -94px;
                            left: -94px;
                        }
                        
                        &:nth-of-type(2) {
                            bottom: 0;
                            left: -188px;
                            height: 176px;
                        }
                    }
                }
            }
        }
    }
    
    &.right {
        .row {
            .image-container {
                &.shapes {
                    margin-bottom: 200px;
                    position: relative;
                    outline: 8px solid white;
    
                    .shape {
                        position: absolute;
                        z-index: -1;
    
                        &:nth-of-type(1) {
                            top: -96px;
                            right: -96px;
                        }
                        
                        &:nth-of-type(2) {
                            bottom: -94px;
                            right: -94px;
                            width: 176px;
                            height: 176px;
                        }
                    }
                }
            }
        }
    }

    @include mq($until: 920px) {
        &.left, &.right {
            display: none;
        }  
        
        &.mobile {
            &.hidden {
                display: flex !important;
                flex-direction: column;
                align-items: center;
                width: 100%;

                .row {
                    .image-container {
                        &.shapes {
                            margin-bottom: 0;

                            .shape {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    
    @include mq($until: $viewport--sm) {
        &.mobile {
            &.hidden {
                .row {
                    .textblock {
                        .info {
                            margin-bottom: 0;

                            .text {
                                margin-bottom: 0;
                            }
                        }
                    }
    
                    &:not(:first-of-type) {                        
                        margin-top: 80px;
                    }
                }
            }
        }
    }
}
