// Overview
.BlogOverzichtPage, .NieuwsOverzichtPage {
    .page-header {
        margin-left: $spacing--xxl;
        margin-bottom: 400px;
        position: relative;
        z-index: 1;
                
        &.amount {
            &-1 {
                padding-left: 90px;
            }
        }
    
        > div {
            margin-top: 20px;
            max-width: 520px;
        }

        @include mq($from: 1800px) {            
            padding-left: 120px;
            margin-left: auto;
            margin-right: auto;
            max-width: 1400px;

            &.amount {
                &-1 {
                    padding-left: 210px;                
                }
            }
        }
        
        @include mq($until: 1600px) {            
            &.amount {
                &-1 {
                    padding-left: 20px;
                }
            }
        }        
    
        @include mq($until: 1400px) {            
            margin-left: 200px;
            margin-right: 200px;
            margin-bottom: 350px;

            &.amount {
                &-1 {
                    padding-left: 50px;
                }
            }
        }
        
        @include mq($until: 960px) {            
            margin-left: 120px;
            margin-right: 120px;
            margin-bottom: 320px;

            &.amount {
                &-1 {
                    padding-left: 0;
                }
            }
        }
        
        @include mq($until: $viewport--md) {            
            margin-left: 60px;
            margin-right: 60px;     
        }
        
        @include mq($until: $viewport--sm) {            
            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 80px;
        }
    }
}

// Detail
.BlogPage, .NewsPage {
    .page {
        &-content {
            padding: 0 40px;
            margin-bottom: 200px;
            
            &--intro {
                font-size: 20px;
                line-height: 28px;
            }     
            
            &--image {
                height: 400px;

                * {
                    height: inherit;
                }
            }

            &--container {
                margin-top: 90px;

                > * {
                    margin: 90px auto;

                    &.page-content {
                        &--image {
                            margin: 140px auto;
                        }
                        
                        &--quote {
                            margin: 240px auto;
                        }
                    }
    
                    &:first-child {
                        margin-top: 0 !important;
                    }
                    
                    &:last-child {
                        margin-bottom: 0 !important;
                    }                    
                }
            }

            &--text {
                h3 {
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: bold;
                    margin-bottom: 15px;
                }

                .text {
                    font-family: $font-secondary;
                    font-size: 16px;
                }
            }

            &--intro, &--text, &--quote {
                max-width: 800px;
                margin-left: auto;
                margin-right: auto;
            }   

            @include mq($until: 920px) {
                &--quote {
                    .quote {
                        .info {
                            padding-left: 0;
                        }
                    }
                }
            }
            
            @include mq($until: $viewport--md) {
                padding: 0 30px;
                
                &--container {
                    margin-top: 50px;

                    > * {
                        margin: 50px auto;

                        &.page-content {
                            &--image {
                                margin: 50px auto;
                            }

                            &--quote {
                                margin: 150px auto;
                            }
                        }
                    }
                }

                &--intro {
                    font-size: 16px;
                    line-height: 24px;
                }

                &--quote {                    
                    .quote {
                        padding: 0;
                        
                        .figure {
                            position: relative;
                            z-index: -1;
                        }
    
                        .info {
                            font-size: 16px;
                            line-height: 28px;
    
                            &::before {
                                top: -20px;
                            }
                        }
                    }
                }
            }
            
            @include mq($until: $viewport--sm) {
                margin-bottom: 80px;
                
                &--quote {    
                    margin: 100px auto;

                    .quote {
                        flex-direction: column-reverse;
                        align-items: center;

                        .info {
                            margin-left: auto;
                            margin-right: auto;
                            margin-top: 120px;
                        }

                        .figure {
                            .shape {
                                &:nth-of-type(1) {
                                    left: -50px;
                                    top: -50px;
                                }
                            }
                        }
                    }
                }

                &--image {
                    height: 240px;
                }

                .textblock {
                    margin-bottom: 0;
                }
            }
        }

        &-header {
            display: grid;
            grid-template-columns: 520px auto 370px;
            grid-template-rows: repeat(5, auto);
            grid-template-areas: 'title . image1'
            'title . image1'
            'image2 . image1'
            'image2 . image1';
        
            &--container {
                margin: 0 $spacing--normal;
                margin-bottom: 200px;
            }
        
            .figure {
                &-1 {
                    grid-area: image1;
                    max-width: 370px;
                    height: 420px;
                    position: relative;
                    margin-top: 60px;
        
                    .image {
                        max-width: 370px;
                        height: 420px;
                        overflow: hidden;
                    }
        
                    .shape {
                        &:nth-of-type(1) {
                            bottom: -186px;
                            right: -6px;
                        }
                        
                        &:nth-of-type(2) {
                            bottom: -94px;
                            right: -94px;
                        }
                    }
                }
                
                &-2 {
                    grid-area: image2;
        
                    .image {
                        max-width: 520px;
                        height: 420px;
                    }
        
                    .shape {
                        bottom: -96px;
                        left: -96px;
                    }
                }
        
                &-1, &-2 {
                    position: relative;
        
                    .shape {
                        position: absolute;
                    }
                }
            }
            
            .title {
                grid-area: title;
                padding-left: 70px;
                padding-bottom: 100px;
                padding-right: 40px;
            }

            @include mq($until: 1350px) {
                grid-template-columns: 460px auto 370px;

                .title {
                    padding-left: 0px;
                }
            }
            
            @include mq($until: 1250px) {
                &--container {
                    margin: 0 40px;
                    margin-bottom: 250px;
                }
            }
            
            @include mq($until: 960px) {
                grid-template-columns: 1fr 40px 320px;
            }
            
            @include mq($until: $viewport--md) {
                grid-template-columns: 1fr;
                justify-content: center;

                grid-template-areas:
                'title'
                'image1';
                
                .title {                    
                    padding-bottom: 0;
                    padding-right: 0;
                    max-width: 370px;
                    width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                }

                .figure {
                    margin-left: auto;
                    margin-right: auto;
                    width: 100%;

                    &-2 {
                        display: none;
                    }
                }
            }

            @include mq($until: $viewport--sm) {
                &--container {
                    margin: 0 30px;
                    margin-bottom: 140px;
                }

                .figure {
                    .image {
                        height: 300px;
                    }

                    .shape {
                        &:nth-of-type(1) {
                            bottom: -104px;
                            right: -2px;
                            height: 46px;
                        }

                        &:nth-of-type(2) {
                            bottom: -52px;
                            right: -52px;
                        }
                    }
                }
            }
        }
    }
}