.container {
    max-width: 1060px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    &--sm {
        max-width: 760px;
    }
    
    &--md {
        max-width: 850px;
    }

    @include mq($from: $viewport--xxl) {
        max-width: 1150px;

        &--sm {
            max-width: 760px;
        }

        &--md {
            max-width: 850px;
        }
    }

    @include mq($until: 1280px) {
        max-width: 940px;

        &--sm {
            max-width: 760px;
        }

        &--md {
            max-width: 850px;
        }
    }
    
    @include mq($until: 1060px) {
        max-width: 860px;

        &--sm {
            max-width: 760px;
        }

        &--md {
            max-width: 850px;
        }
    }
}